body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

footer {
  background-color: #f9f9f9; /* 使用浅灰色背景 */
  color: #4a4a4a; /* 深灰色文本 */
  padding: 20px 0;
  text-align: center;
  border-top: 1px solid #e0e0e0;
  font-size: 0.9rem;
}

footer p {
  margin: 5px 0;
}

footer a {
  color: #337ab7; /* 柔和的蓝色链接 */
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
  color: #23527c; /* 悬停时的深蓝色 */
}